import BigNumber from '@exchange/helpers/bignumber';
import { type IncomingSymbol } from '@exchange/libs/rest-api/eotc-api/symbols';

export type InstrumentCode = `${string}-${string}`;
export interface TradingPairsOption {
  base: string;
  direction: 'BUY' | 'SELL';
  instrumentCode: InstrumentCode;
  minSize: string;
  precision: number;
  quote: string;
  symbol: string;
  pricePrecision: number;
  sizePrecision: number;
}
export interface TradingPairCurrency {
  symbol: string;
  markets: Record<string, TradingPairsOption>;
  precision: number;
}
export type TradingPairs = Record<string, TradingPairCurrency>;

const processIncomingPairs = (symbols: Array<IncomingSymbol>) => {
  const processedSymbols = symbols.reduce((acc, cur) => {
    const baseCode = cur.base.code;
    const basePrecision = cur.base.precision;
    const quoteCode = cur.quote.code;
    const quotePrecision = cur.quote.precision;

    const getPrecision = (v?: string) => (v ? (new BigNumber(v).decimalPlaces() ?? 8) : 8);

    const instrumentCode: InstrumentCode = `${baseCode}-${quoteCode}`;
    const pricePrecision = getPrecision(cur.min_price_inc);
    const sizePrecision = getPrecision(cur.min_size_inc);
    const pairOption = {
      base: baseCode,
      instrumentCode,
      quote: quoteCode,
      pricePrecision,
      sizePrecision,
    };
    const pairOptionBase = {
      ...pairOption,
      direction: 'SELL' as const,
      minSize: cur.min_size ?? '0',
      symbol: quoteCode,
      precision: quotePrecision,
    };
    const pairOptionQuote = {
      ...pairOption,
      direction: 'BUY' as const,
      minSize: cur.min_amt ?? '0',
      symbol: baseCode,
      precision: basePrecision,
    };

    if (!acc[baseCode]) {
      acc[baseCode] = {
        symbol: baseCode,
        precision: basePrecision,
        markets: {
          [quoteCode]: pairOptionBase,
        },
      };
    } else {
      const c = acc[baseCode];

      if (c && c.markets) {
        c.markets[quoteCode] = pairOptionBase;
      }
    }

    if (!acc[quoteCode]) {
      acc[quoteCode] = {
        symbol: quoteCode,
        precision: quotePrecision,
        markets: {
          [baseCode]: pairOptionQuote,
        },
      };
    } else {
      const c = acc[quoteCode];

      if (c && c.markets) {
        c.markets[baseCode] = pairOptionQuote;
      }
    }

    return acc;
  }, {} as TradingPairs);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).processedSymbols = processedSymbols;

  return processedSymbols;
};

export default processIncomingPairs;
