import { ref } from 'vue';

import BigNumber from '@exchange/helpers/bignumber';
import fulfillWithTimeLimit from '@exchange/helpers/fulfill-with-time-limit';
import { authService } from '@exchange/libs/auth/service/src';
import { BalanceModel, getSum } from '@exchange/libs/balances/service/src';
import EotcRest from '@exchange/libs/rest-api/eotc-api';
import WebRest from '@exchange/libs/rest-api/web-api';
import { toastManagerInstance, SimpleToast } from '@exchange/libs/toasts/src';
import { calculateTotalBalanceInFiat } from '@exchange/libs/trading-accounts/service/src';
import { currencyService, type CurrencyModel } from '@exchange/libs/utils/currency/src';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import { EOTCChannel } from './eotc-websockets';
import { WSPacket, WSPacketType, QuoteUpdateData, ExecutionReportData, EOTCRequest } from './eotc-websockets.types';
import processIncomingPairs, { type TradingPairs } from './process-incoming-pairs';

export type UIUpdates = {
  onExecution: (data: ExecutionReportData) => void;
  onGenericError: (errorMessage?: string) => void;
  onNoAuth: () => void;
  onNoPrice: () => void;
  onQuote: (data: QuoteUpdateData) => void;
  onQuoteBooked: () => void;
  onReconnect: () => void;
};

class EOTCService {
  private readonly logPrefix = 'EOTCService:';

  public readonly accountIdInstant = 'instant-trade-account';

  public balances = ref<Array<BalanceModel>>([]);

  private EOTCChannel = new EOTCChannel();

  private unsubscribe?: () => Promise<void>;

  private unsubscribeEOTC = () =>
    (this.unsubscribe?.() || Promise.resolve()).catch((e) => {
      logger.error(`${this.logPrefix} unsubscribe failed`, e);
    });

  private websocketMessageProcessor = (uiUpdates: UIUpdates) => (wsPacket: WSPacket) => {
    if (wsPacket.success === false) {
      if ('error' in wsPacket && wsPacket.error === 'No prices currently available') {
        uiUpdates.onNoPrice();
        return;
      }

      if ('error' in wsPacket) {
        logger.error(this.logPrefix, wsPacket.error);
        uiUpdates.onGenericError(wsPacket.error);
        return;
      }

      uiUpdates.onGenericError();
      return;
    }

    if (!('type' in wsPacket)) {
      return;
    }

    if (wsPacket.type === WSPacketType.QuoteBooked) {
      uiUpdates.onQuoteBooked();
      return;
    }

    if (wsPacket.type === WSPacketType.QuoteUpdate) {
      const eData = wsPacket.data as QuoteUpdateData;

      uiUpdates.onQuote(eData);
      return;
    }

    if (wsPacket.type === WSPacketType.ExecutionReport) {
      const eData = wsPacket.data as ExecutionReportData;

      uiUpdates.onExecution(eData);
      return;
    }

    if (wsPacket.type === WSPacketType.NotAuthorized) {
      uiUpdates.onNoAuth();
    }
  };

  private authenticate = () =>
    this.EOTCChannel.authenticate(authService.getRefreshedToken).catch((e) => {
      logger.info(`${this.logPrefix} ws authentication failed`, e);
    });

  public setupWebsocket = async (uiUpdates: UIUpdates) => {
    try {
      await this.subscribe(uiUpdates);
      await this.authenticate();
    } catch (e) {
      logger.error(`${this.logPrefix} ws setup failed`, e);
    }
  };

  public closeWebsocket = async () => {
    await this.unsubscribeEOTC();
  };

  public async subscribe(uiUpdates: UIUpdates) {
    try {
      await this.unsubscribeEOTC();
      await new Promise<void>((res, rej) => {
        this.unsubscribe = this.EOTCChannel.subscribe(
          {
            onMessage: this.websocketMessageProcessor(uiUpdates),
            onConnection: () => {
              uiUpdates.onReconnect();
              return this.authenticate();
            },
          },
          {
            success: () => res(),
            fail: (error) => rej(error),
          },
        );
      });
    } catch (error) {
      logger.error(`${this.logPrefix} subscription failed`, error);
    }
  }

  public async sendRequest(data: EOTCRequest) {
    try {
      await this.EOTCChannel.sendRequest(data);
    } catch (error) {
      logger.error(`${this.logPrefix} send request failed`, error);
      throw error;
    }
  }

  public fetchBalances = async () => {
    let success = false;

    try {
      const accounts = await EotcRest.Balances.getAll();
      const balances = accounts.map(
        (i) =>
          new BalanceModel({
            available: i.available_amount,
            locked: i.locked_amount,
            currency_code: i.currency,
            sequence: -1,
          }),
      );

      this.balances.value = await this.updateBalancesTotalValues(balances);
      success = true;

      return success;
    } catch (e) {
      logger.error(`${this.logPrefix} fetching balances failed`, e);

      return success;
    }
  };

  public runFetchBalances = async (): Promise<boolean> => {
    try {
      const success = await this.fetchBalances();

      if (!success) {
        throw new Error('balance_issue');
      }

      return this.areAllBalancesEmpty();
    } catch (e) {
      toastManagerInstance.addToast({
        content: SimpleToast,
        props: {
          variant: 'failed',
          title: 'fundamentals.error.title',
          message: 'fundamentals.error.text',
        },
      });

      await retryService.waitForNextRetryTick();
      return this.runFetchBalances();
    }
  };

  public getBalanceFor = (symbol: string) => this.balances.value.find((b) => b.currencyCode === symbol);

  public areAllBalancesEmpty = () => this.balances.value.every((b) => b.available.eq(0));

  private updateBalancesTotalValues = async (balances: Array<BalanceModel>) => {
    await fulfillWithTimeLimit<Dictionary<CurrencyModel>>(currencyService.waitForCurrencies(), 5_000, {});
    const currencies = currencyService.currencies.value;

    Object.values(balances).forEach((balance) => {
      balance.calculateAndUpdateTotalValues(currencies?.[balance.currencyCode]);
    });

    const tvc = Object.values(balances).reduce((acc, balance) => getSum(balance.totalCryptoValue, acc), new BigNumber(0));

    Object.values(balances).forEach((balance) => {
      const portfolioSize = balance.getPortfolioSize(tvc, balance.totalCryptoValue);

      balance.updatePortfolioSize(portfolioSize);
    });

    return balances;
  };

  private getTotalBalances = () => {
    const balances = this.balances.value as Array<BalanceModel>; // vue UnwrapRef type removes _isBigNumber private props

    return calculateTotalBalanceInFiat(balances, currencyService.currencies.value);
  };

  public getTotalFiatAmount = () => this.getTotalBalances().all;

  public getTotalLockedFiatAmount = () => this.getTotalBalances().locked;

  public transfer = async (data: { amount: string; currency: string; source: string; destination: string }) => {
    const direction = data.source === this.accountIdInstant ? 'eotc_to_exchange' : 'exchange_to_eotc';

    return WebRest.Funding.EOTC.Transfer.post({
      amount: data.amount,
      currencyCode: data.currency,
      direction,
    });
  };

  public fetchTrades = EotcRest.Trades.getAll;

  public fetchTradePairs = async (): Promise<TradingPairs> => {
    try {
      const symbols = await EotcRest.Symbols.getAll();

      return processIncomingPairs(symbols);
    } catch (e) {
      logger.error(`${this.logPrefix} fetching trading pairs failed`, e);
      await retryService.waitForNextRetryTick();
      return this.fetchTradePairs();
    }
  };
}

const eotc = new EOTCService();

export default eotc;
