/* eslint-disable camelcase */
import BigNumber from '@exchange/helpers/bignumber';

export interface BEFeeTier {
  volume: string;
  fee_group_id: string;
  maker_fee: string;
  taker_fee: string;
}

interface BEFeeGroupBase {
  account_id: string;
  active_fee_tier: BEFeeTier;
  collect_fees_in_best: boolean;
  fee_discount_rate: string;
  fee_group_id: string;
  fee_tiers: Array<BEFeeTier>;
  minimum_price_value: string;
  user_is_blocked?: boolean;
}

export interface BEFeeGroupCurrent extends BEFeeGroupBase {
  running_volume: string;
  running_volume_currency: string;
}

export interface BEFeeGroupLegacy extends BEFeeGroupBase {
  running_trading_volume: string;
}

export type BEFeeGroup = BEFeeGroupCurrent | BEFeeGroupLegacy;

export enum FeeType {
  maker = 'maker',
  taker = 'taker',
}

export interface Fees {
  makerFee: number;
  takerFee: number;
}

export class FeeTier {
  public volume!: number;

  public originalFees!: Fees;

  public reducedFees!: Fees;

  constructor(
    fields: BEFeeTier,
    public discountRate: number,
  ) {
    const maker = Number(fields.maker_fee);
    const taker = Number(fields.taker_fee);

    this.reducedFees = {
      makerFee: this.calculateFee(maker),
      takerFee: this.calculateFee(taker),
    };
    this.volume = Number(fields.volume);
    this.originalFees = {
      makerFee: maker,
      takerFee: taker,
    };
  }

  protected calculateFee(fee: number) {
    return new BigNumber(fee).minus(new BigNumber(fee).times(new BigNumber(this.discountRate).div(100))).toNumber();
  }
}

export class FeeGroup {
  public id!: string;

  public feeTiers!: Array<FeeTier>;

  public reducedFeeTiers!: Array<FeeTier>;

  public feeDiscountRate!: number;

  constructor(fields: BEFeeGroup) {
    this.id = fields.fee_group_id;
    const discount = Number(fields.fee_discount_rate);

    this.feeTiers = fields.fee_tiers.map((tier) => new FeeTier(tier, discount));
    this.feeDiscountRate = discount;
  }
}
