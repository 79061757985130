export enum ExportType {
  trades = 'TRADES',
  transactions = 'TRANSACTIONS',
}

export enum ExportState {
  inProgress = 'IN_PROGRESS',
  completed = 'COMPLETED',
  failed = 'FAILED',
}

/* eslint-disable camelcase */
export interface BEExport {
  export_id: string;
  created: string;
  expiration: string;
  state: ExportState;
  type: ExportType;
  from: string;
  to: string;
  markets: Array<string>;
  currencies: Array<string>;
  target_accounts: Array<string>;
}

export interface BEExportsList {
  account_holder: string;
  exports: Array<BEExport>;
}

export interface BERequestTransactionsExport {
  from?: string;
  to?: string;
  currencies: Array<string>;
  target_accounts: Array<string>;
}

export interface BERequestTradesExport {
  from?: string;
  to?: string;
  markets: Array<string>;
  target_accounts: Array<string>;
}
/* eslint-enable camelcase */

export default class ExportItem {
  public id!: string;

  public created!: string;

  public expiration!: string;

  public state!: ExportState;

  public type!: ExportType;

  public from?: string;

  public to?: string;

  public markets!: Array<string>;

  public currencies!: Array<string>;

  public targetAccounts!: Array<string>;

  public accounts!: Array<string>;

  constructor(fields: BEExport) {
    this.id = fields.export_id;
    this.created = fields.created;
    this.expiration = fields.expiration;
    this.state = fields.state;
    this.type = fields.type;
    this.from = fields.from;
    this.to = fields.to;
    this.markets = fields.markets || [];
    this.currencies = fields.currencies || [];
    this.targetAccounts = fields.target_accounts;
  }

  public fillAccounts = (accounts: Array<{ accountId: string; name: string }>) => {
    this.accounts = this.targetAccounts.reduce((acc, account) => {
      const v = accounts.find((e) => e.accountId === account)?.name;

      if (v) {
        acc.push(v);
      }

      return acc;
    }, [] as Array<string>);
  };
}
