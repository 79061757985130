<script lang="ts" setup>
import { computed, onUnmounted, type PropType, watch } from 'vue';

import usePromise from '@exchange/libs/composables/shared/src/lib/usePromise';
import { ExportItem, ExportState } from '@exchange/libs/exports/service/src';

const props = defineProps({
  downloadItem: { type: Function as PropType<(id: string, isTradeItem: boolean) => Promise<void>>, required: true },
  fetchItem: { type: Function as PropType<(id: string, isTradeItem: boolean) => Promise<void>>, required: true },
  item: { type: Object as PropType<ExportItem>, required: true },
});

let refetchInterval: number | undefined;
const isTradeItem = computed(() => props.item.type === 'TRADES');
const download = usePromise(async () => {
  await props.downloadItem(props.item.id, isTradeItem.value);
});
const loading = computed(() => download.loading.value);
const isDownloadDisabled = computed(() => props.item.state !== ExportState.completed || loading.value);
const isFailed = computed(() => props.item.state === ExportState.failed);

watch(
  () => props.item.state === ExportState.inProgress,
  (value) => {
    clearInterval(refetchInterval);

    if (value) {
      refetchInterval = window.setInterval(() => {
        props.fetchItem(props.item.id, isTradeItem.value);
      }, 10_000);
    }
  },
  { immediate: true },
);

onUnmounted(() => clearInterval(refetchInterval));
</script>

<template>
  <x-button
    v-if="!isFailed"
    class="export-tem-download"
    size="xs"
    :text="$t('fundamentals.download')"
    :disabled="isDownloadDisabled"
    :loading="isDownloadDisabled || loading"
    variant="dark-outline"
    @click.stop="download.createPromise"
  />
  <div
    v-else
    class="export-tem-download"
  >
    <div class="export-tem-download__failed">{{ $t('fundamentals.error.title') }}</div>
  </div>
</template>

<style lang="scss">
.export-tem-download {
  &__failed {
    padding: 0 16px;
    color: rgb(var(--v-theme-error));
  }
}
</style>
