import { last, pipe, isEmpty, split } from 'lodash/fp';
import orderBy from 'lodash/orderBy';
import { computed, reactive, shallowRef } from 'vue';

import csvDownloader from '@exchange/helpers/csv-downloader';
import usePromise, { displayDefaultFailedToast } from '@exchange/libs/composables/shared/src/lib/usePromise';
import { ExportHistoryModal } from '@exchange/libs/exports/ui/src';
import { marketService } from '@exchange/libs/market/service/src';
import { modalService } from '@exchange/libs/modals/src';
import WebRest from '@exchange/libs/rest-api/web-api';
import { subaccountsService } from '@exchange/libs/trading-accounts/service/src';
import { currencyService } from '@exchange/libs/utils/currency/src';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import ExportItem, { BEExport } from './export-model';

export interface GenerateTradeData {
  requested: boolean;
  markets: Array<string>;
  accounts: Array<string>;
}

export interface GenerateTransactionsData {
  requested: boolean;
  currencies: Array<string>;
  accounts: Array<string>;
}

export interface GenerateData {
  trades: GenerateTradeData;
  transactions: GenerateTransactionsData;
  from?: string;
  to?: string;
}

interface ExportsServiceState {
  list: Array<ExportItem>;
}

const getAttachmentName = pipe(split('='), last, (s) => (isEmpty(s) ? undefined : s));
const getNameFromUrl = (url: string) => {
  try {
    const { pathname } = new URL(url);

    return pathname.split('/').pop();
  } catch (e) {
    logger.log(`Invalid url ${url}`);
    return undefined;
  }
};

export class ExportsService {
  private readonly logPrefix = 'ExportsService: ';

  private readonly exportDefaultFileNamePrefix = 'One Trading';

  private readonly state = reactive<ExportsServiceState>({
    list: [],
  });

  public list = computed(() => {
    this.state.list.map((e) => e.fillAccounts(subaccountsService.fullList.value));

    return orderBy(this.state.list, ['created'], ['desc']);
  });

  private getExportList = async (attempt = 0) => {
    try {
      this.state.list = await WebRest.Exports.getAll();
    } catch (e) {
      logger.error(`${this.logPrefix}Getting list failed`, e);

      if (attempt < 3) {
        await retryService.waitForNextRetryTick();
        await this.getExportList(attempt + 1);
      } else {
        throw e;
      }
    }
  };

  private getExportListInfo = usePromise(this.getExportList);

  public fetchingList = computed(() => this.getExportListInfo.loading.value);

  public fetchingListError = computed(() => this.getExportListInfo.error.value);

  public fetchExportListsNow: () => Promise<void> = this.getExportListInfo.createPromise;

  public fetchExportItem = async (id: string) => {
    try {
      const item = await WebRest.Exports.Export(id).get();
      const idx = this.state.list.findIndex((e) => e.id === item.id);

      if (idx !== undefined) {
        this.state.list[idx] = item;
      }
    } catch (e) {
      await retryService.waitForNextRetryTick();
      await this.fetchExportItem(id);
    }
  };

  public downloadExportItem = async (id: string) => {
    try {
      const { data, filename } = await WebRest.Exports.Export(id).download();
      const name = getAttachmentName(filename);
      const nameFromUrl = data.url ? getNameFromUrl(data.url) : '';

      if (!data.url) {
        throw new Error('malformed response, ulr is missing');
      }

      await csvDownloader({
        url: data.url,
        name: name || nameFromUrl || this.exportDefaultFileNamePrefix,
        suffix: true,
      });
    } catch (e) {
      logger.error(`${this.logPrefix}downloading failed`, e);
      displayDefaultFailedToast();
    }
  };

  private generateExport = async (data: GenerateData) => {
    const requests: Array<Promise<BEExport>> = [];
    const { from, to } = data;
    const sharedPayloadPart = {
      target_accounts: data.trades.accounts,
      ...(from && { from }),
      ...(to && { to }),
    };

    if (data.trades.requested) {
      requests.push(
        WebRest.Exports.trades({
          markets: data.trades.markets,
          ...sharedPayloadPart,
        }),
      );
    }

    if (data.transactions.requested) {
      requests.push(
        WebRest.Exports.transactions({
          currencies: data.transactions.currencies,
          ...sharedPayloadPart,
        }),
      );
    }

    return Promise.all(requests);
  };

  public showNewExportModal = () => {
    const { listWithInstantTrade: subaccounts } = subaccountsService;
    const { currencies } = currencyService;
    const { markets } = marketService;

    const exportModal = modalService.show(
      shallowRef(ExportHistoryModal),
      {
        subaccounts,
        currencies: computed(() => (currencies.value ? Object.values(currencies.value) : [])),
        markets: computed(() => (markets.value ? Object.keys(markets.value) : [])),
        generate: async (payload: GenerateData) => {
          await this.generateExport(payload);
          modalService.hide(exportModal);
          await retryService.waitForNextRetryTick();
          await this.fetchExportListsNow();
        },
      },
      {},
    );
  };
}

const exportsService = new ExportsService();

export default exportsService;
