<script lang="ts" setup>
import { createFlexTableColumn, type OtFlexTableColumn } from '@1trading/design-system';
import { computed, inject, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import useMQBreakpoints from '@exchange/libs/composables/shared/src/lib/useMQBreakpoints';
import useOpenItem from '@exchange/libs/composables/shared/src/lib/useOpenItem';
import { type ExportItem } from '@exchange/libs/exports/service/src';

import ExportTableRow from './ExportTableRow.vue';

defineProps({
  list: { type: Array as PropType<Array<ExportItem>>, required: true },
  error: { type: String, required: false },
  loading: { type: Boolean, required: true },
  fetchList: { type: Function as PropType<() => Promise<void>>, required: true },
  fetchItem: { type: Function as PropType<(id: string, isTradeItem: boolean) => Promise<void>>, required: true },
  downloadItem: { type: Function as PropType<(id: string, isTradeItem: boolean) => Promise<void>>, required: true },
});

const { t } = useI18n({ useScope: 'global' });
const { isMD, isLG, isXL } = useMQBreakpoints();
const { openItemId, setOpenItemId } = useOpenItem();

const bigScreen = computed(() => isLG.value || isXL.value);

const columns = computed(() => {
  const columnActions: OtFlexTableColumn = {
    ...createFlexTableColumn({
      title: '',
      class: 'action-column',
    }),
  };
  const columnRequested: OtFlexTableColumn = {
    ...createFlexTableColumn({
      title: t('modules.exports.table.headers.requested'),
      class: 'requested-column',
    }),
  };
  const columnType: OtFlexTableColumn = {
    ...createFlexTableColumn({
      title: t('modules.exports.table.headers.type'),
      class: 'type-column',
    }),
  };
  const columnTimeframe: OtFlexTableColumn = {
    ...createFlexTableColumn({
      title: t('modules.exports.table.headers.timeframe'),
      class: 'timeframe-column',
    }),
  };
  const columnExpiry: OtFlexTableColumn = {
    ...createFlexTableColumn({
      title: t('modules.exports.table.headers.expiry'),
      class: 'expiry-column',
    }),
  };

  const toShow = [columnRequested, columnType, columnActions];

  if (isMD.value) {
    toShow.splice(2, 0, columnExpiry);
  } else if (bigScreen.value) {
    toShow.splice(2, 0, columnTimeframe, columnExpiry);
  }

  return toShow;
});

const utcToLocal = inject<(utcDateString: string | Date | number, formatString?: string) => string>('utcToLocal');
</script>

<template>
  <ot-async-grid-table
    class="exports-table full-width"
    header-class="exports-table__header"
    :columns="columns"
    :data="list"
    :error="error"
    :loading="loading"
    :with-empty-message="true"
    :narrow="true"
    :retry="fetchList"
  >
    <template #default>
      <export-table-row
        v-for="item in list"
        :id="item.id"
        :key="item.id"
        :item="item"
        :collapsed="openItemId !== item.id"
        :get-local-time="utcToLocal"
        :fetch-item="fetchItem"
        :download-item="downloadItem"
        @click="setOpenItemId(item.id)"
      />
    </template>
    <template
      v-if="!list.length"
      #footer
    >
      <p class="exports-table__empty-message">{{ $t('modules.exports.table.empty') }}</p>
    </template>
  </ot-async-grid-table>
</template>

<style lang="scss">
.exports-table {
  --grid-table-template-columns-custom: 2fr 1.5fr 2fr 1fr 2fr;
  --x-grid-table-column-gap-custom: 10px;

  .md & {
    --grid-table-template-columns-custom: 1fr 1fr 1fr 0.75fr;
  }

  .sm & {
    --grid-table-template-columns-custom: 1fr 1fr 0.85fr;
  }

  .xs & {
    --grid-table-template-columns-custom: 0.85fr 1fr 1.35fr;
    --x-grid-table-column-gap-custom: 5px;
  }

  &__empty-message {
    display: flex;
    justify-content: center;
    color: rgb(var(--v-theme-text-secondary));
  }

  .requested-column {
    white-space: normal;
  }

  .cell-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
