<script lang="ts" setup>
import { isEmpty, orderBy } from 'lodash/fp';
import { computed, reactive, type PropType, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import HistoryRangePicker from '@exchange/libs/components/history/HistoryRangePicker.vue';
import useMQBreakpoints from '@exchange/libs/composables/shared/src/lib/useMQBreakpoints';
import { usePromiseThrowing } from '@exchange/libs/composables/shared/src/lib/usePromise';
import { type GenerateData } from '@exchange/libs/exports/service/src';
import { modalVariant, type ModalVariant } from '@exchange/libs/modals/src';
import { toastManagerInstance, SimpleToast } from '@exchange/libs/toasts/src';
import type { Subaccount } from '@exchange/libs/trading-accounts/service/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import type { CurrencyModel } from '@exchange/libs/utils/currency/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

interface ExportHistoryModalState {
  selectedTimeframe: {
    from?: string;
    to?: string;
  };
  selectedSubaccounts: Array<Subaccount>;
  selectedExportTrades: boolean;
  selectedExportTransactions: boolean;
  selectedBase: Array<CurrencyModel>;
  selectedQuote: Array<CurrencyModel>;
  selectedCurrencies: Array<CurrencyModel>;
}

const props = defineProps({
  currencies: { type: Object as PropType<Array<CurrencyModel>>, required: true },
  generate: { type: Function as PropType<(data: GenerateData) => Promise<void>>, required: true },
  markets: { type: Object as PropType<Array<string>>, required: true },
  subaccounts: { type: Object as PropType<Array<Subaccount>>, required: true },
  variant: { type: String as PropType<ModalVariant>, default: modalVariant.dark },
});

const { t, te } = useI18n({ useScope: 'global' });

const { isXS } = useMQBreakpoints();
const currencyOptions = computed(() => orderBy(['id'], ['asc'], props.currencies));
const subaccountsOptions = computed(() => props.subaccounts);

const state = reactive<ExportHistoryModalState>({
  selectedTimeframe: {
    from: undefined,
    to: undefined,
  },
  selectedSubaccounts: [...subaccountsOptions.value],
  selectedExportTrades: false,
  selectedExportTransactions: false,
  selectedBase: [...currencyOptions.value],
  selectedQuote: [...currencyOptions.value],
  selectedCurrencies: [...currencyOptions.value],
});

const generateExportDisabled = computed(() => {
  const tradesAreNotOk = state.selectedExportTrades && (state.selectedBase.length === 0 || state.selectedQuote.length === 0);
  const transactionsAreNotOk = state.selectedExportTransactions && state.selectedCurrencies.length === 0;

  return (!state.selectedExportTrades && !state.selectedExportTransactions) || state.selectedSubaccounts.length === 0 || tradesAreNotOk || transactionsAreNotOk;
});

const generateMarketsFormBaseQuote = () => {
  const base = state.selectedBase;
  const quote = state.selectedQuote;
  const currenciesNumber = currencyOptions.value.length;
  const pairs = base.flatMap((b) => quote.map((q) => `${b.id}_${q.id}`));

  if ((base.length === currenciesNumber && quote.length === currenciesNumber) || pairs.length >= CONSTANTS.EXPORT_TRADES_MAX_PAIR_NUMBER) {
    return [];
  }

  return pairs;
};

const { loading: generating, createPromise: generateExport } = usePromiseThrowing(async () => {
  const accounts = state.selectedSubaccounts.map((a) => a.accountId);

  const trades = {
    requested: state.selectedExportTrades,
    markets: generateMarketsFormBaseQuote(),
    accounts,
  };

  const transactions = {
    requested: state.selectedExportTransactions,
    currencies: state.selectedCurrencies.map((c) => c.id),
    accounts,
  };

  await props
    .generate({
      trades,
      transactions,
      ...(!isEmpty(state.selectedTimeframe.from) && { from: state.selectedTimeframe.from }),
      ...(!isEmpty(state.selectedTimeframe.to) && { to: state.selectedTimeframe.to }),
    })
    .catch((e) => {
      logger.error('Generation export failed', e);

      const error = e?.data?.error;
      const lKey = `modules.accountHistory.export.requests.errors.${error}`;
      const message = te(lKey) ? t(lKey) : t('fundamentals.error.text');

      toastManagerInstance.addToast({
        content: SimpleToast,
        props: {
          variant: 'failed',
          title: 'modules.apiKeys.delete.failed.title',
          translatedMessage: message,
        },
      });
    });
});

watch(currencyOptions, () => {
  state.selectedCurrencies = [...currencyOptions.value];
  state.selectedBase = [...currencyOptions.value];
  state.selectedQuote = [...currencyOptions.value];
});
watch(subaccountsOptions, () => {
  // @ts-ignore bignumber types...
  state.selectedSubaccounts = [...subaccountsOptions.value];
});
</script>

<template>
  <x-modal
    class="export-history-modal"
    type="xs-fullscreen"
    name="export-history"
    :variant="variant"
    :title="$t('modules.accountHistory.export.modal.title')"
  >
    <template #content>
      <section class="export-history-modal__section">
        <div class="export-history-modal__select export-history-modal__select--timeframe">
          <history-range-picker
            v-model:value="state.selectedTimeframe"
            position="bottom-center"
            :on-modal="true"
            :months-to-show="2"
            :variant="variant === 'dark' ? 'dark' : 'light'"
          />
        </div>
        <div class="export-history-modal__select">
          <div class="export-history-modal__select-title">
            {{ $t('modules.accountHistory.export.modal.dropdowns.selectAccount.title') }}
          </div>
          <x-select-multi
            v-model:value="state.selectedSubaccounts"
            label="name"
            dropdown-max-height="330px"
            :variant="variant === 'dark' ? 'dark-outlined' : 'light-outlined'"
            :multiple="true"
            :menu-top-space="!isXS"
            :options="subaccountsOptions"
          />
        </div>
      </section>
      <section class="export-history-modal__section">
        <ot-checkbox
          v-model:selected="state.selectedExportTrades"
          class="export-history-modal__checkbox"
          :color-variant="variant"
          :label="$t('modules.accountHistory.export.modal.includeTrades.label')"
        />
        <div class="export-history-modal__base-quote">
          <div class="export-history-modal__select-title export-history-modal__base-quote-title--base">
            {{ $t('modules.accountHistory.export.modal.dropdowns.base.title') }}
          </div>
          <x-select-multi
            v-model:value="state.selectedBase"
            class="export-history-modal__base-quote-value--base"
            label="id"
            dropdown-max-height="220px"
            :variant="variant === 'dark' ? 'dark-outlined' : 'light-outlined'"
            :multiple="true"
            :menu-top-space="!isXS"
            :options="currencyOptions"
            :disabled="!state.selectedExportTrades"
          />
          <div class="export-history-modal__base-quote-separator">/</div>
          <div class="export-history-modal__select-title export-history-modal__base-quote-title--quote">
            {{ $t('modules.accountHistory.export.modal.dropdowns.quote.title') }}
          </div>
          <x-select-multi
            v-model:value="state.selectedQuote"
            class="export-history-modal__base-quote-value--quote"
            label="id"
            dropdown-max-height="260px"
            :variant="variant === 'dark' ? 'dark-outlined' : 'light-outlined'"
            :multiple="true"
            :menu-top-space="!isXS"
            :options="currencyOptions"
            :disabled="!state.selectedExportTrades"
          />
        </div>
      </section>
      <section class="export-history-modal__section">
        <ot-checkbox
          v-model:selected="state.selectedExportTransactions"
          class="export-history-modal__checkbox"
          :color-variant="variant"
          :label="$t('modules.accountHistory.export.modal.includeTransactions.label')"
        />
        <div class="export-history-modal__select">
          <div class="export-history-modal__select-title">
            {{ $t('modules.accountHistory.export.modal.dropdowns.selectCurrencies.title') }}
          </div>
          <x-select-multi
            v-model:value="state.selectedCurrencies"
            label="id"
            dropdown-max-height="260px"
            :variant="variant === 'dark' ? 'dark-outlined' : 'light-outlined'"
            :multiple="true"
            :menu-top-space="!isXS"
            :options="currencyOptions"
            :disabled="!state.selectedExportTransactions"
          />
        </div>
      </section>
    </template>
    <template #buttons>
      <x-button
        variant="primary"
        :block="true"
        :loading="generating"
        :disabled="generateExportDisabled"
        @click="generateExport"
      >
        {{ $t('modules.accountHistory.export.modal.actions.generate') }}
      </x-button>
    </template>
  </x-modal>
</template>

<style lang="scss">
.export-history-modal {
  &__section {
    margin-bottom: 20px;
    font-size: var(--font-size-sm);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__select {
    &--timeframe {
      margin-bottom: 18px;
    }
  }

  &__checkbox,
  &__select-title {
    color: rgb(var(--v-theme-text-tertiary));
  }

  &__checkbox {
    width: max-content;
    margin-bottom: 6px;
  }

  &__base-quote {
    display: grid;
    grid-column-gap: 4px;
    grid-template:
      'select-base-title empty select-quote-title'
      'select-base-value separator select-quote-value' / 1fr 4px 1fr;

    &-title--base {
      grid-area: select-base-title;
    }

    &-title--quote {
      grid-area: select-quote-title;
    }

    &-separator {
      padding-top: 12px;
      grid-area: separator;
    }

    &-value--base {
      grid-area: select-base-value;
    }

    &-value--quote {
      grid-area: select-quote-value;
    }
  }
}
</style>
