<script lang="ts" setup>
import { watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import type { RouteLocationNormalized } from 'vue-router';

import { getFromQuery } from '@exchange/helpers/query-param-to-string';
import { exportsService } from '@exchange/libs/exports/service/src';

import ExportTable from './components/ExportTable.vue';

const route = useRoute();
const router = useRouter();
const { fetchingList, fetchingListError, list, showNewExportModal, fetchExportListsNow, fetchExportItem, downloadExportItem } = exportsService;

const handleExportEmailLinks = async (r: RouteLocationNormalized) => {
  const exportId = getFromQuery('export_id', r.query);

  if (!exportId || !list.value.length) {
    return;
  }

  const item = list.value.find((i) => i.id === exportId);

  if (!item) {
    return;
  }

  await downloadExportItem(exportId);
  await router.replace({ name: 'exports' });
};

watch(
  route,
  async (r) => {
    if (!list.value.length) {
      return;
    }

    await fetchExportListsNow();

    await handleExportEmailLinks(r);
  },
  { immediate: true },
);

fetchExportListsNow();
</script>

<template>
  <div class="exports-container">
    <div class="exports-container__header">
      <div class="exports-container__description">
        {{ $t('modules.exports.page.description') }}
      </div>
      <x-button
        class="exports-container__action"
        size="sm"
        variant="primary"
        :text="$t('modules.exports.page.newExport')"
        @click="showNewExportModal"
      />
    </div>
    <div class="exports-container__content">
      <export-table
        :list="list"
        :error="fetchingListError"
        :loading="fetchingList"
        :fetch-list="fetchExportListsNow"
        :fetch-item="fetchExportItem"
        :download-item="downloadExportItem"
      />
    </div>
  </div>
</template>

<style lang="scss">
.exports-container {
  &__action {
    margin-left: auto;
  }

  &__header {
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding-bottom: 24px;
    gap: 8px;
  }

  &__description {
    color: rgb(var(--v-theme-text-secondary));
    font-size: var(--font-size-sm);
  }

  &__content {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
}
</style>
