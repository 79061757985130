import { logger } from '@exchange/libs/utils/simple-logger/src';
import { WebSocketManager } from '@exchange/libs/utils/wss/src';

import { EOTCRequestType, type WSPacket, type EOTCRequest, type AuthRequest } from './eotc-websockets.types';

const EOTCWebSocketManager = new WebSocketManager<WSPacket, EOTCRequest | AuthRequest>({
  url: process.env.VUE_APP_EOTC_SOCKET_API_URL as string,
  connectionTimeout: 15_000,
  withHeartbeat: false,
  name: 'eotcWebSocketManager',
});

export const wssLogPrefix = '[eotc_ws]:';

export type EOTCChannelGetTokenListener = () => Promise<string | undefined>;

export interface EOTCChannelListener {
  onMessage: (event: WSPacket) => void;
  onConnection: () => Promise<void>;
}

export interface SubscribeCallbacks<T = void> {
  success: (data: T) => void;
  fail: (error: Error) => void;
}

export class EOTCChannel {
  protected get channelName() {
    return 'EOTC';
  }

  private listeners: Array<EOTCChannelListener> = [];

  private unsubscribeFromWebsocket?: () => void;

  public subscribe(listener: EOTCChannelListener, callbacks?: SubscribeCallbacks) {
    this.listeners.push(listener);

    if (this.listeners.length === 1) {
      this.openChannel(listener)
        .then(() => callbacks?.success())
        .catch((error) => callbacks?.fail(error));
    } else {
      callbacks?.success();
    }

    return async () => {
      this.listeners = this.listeners.filter((l) => l !== listener);

      if (this.listeners.length === 0) {
        await this.closeChannel();
      }
    };
  }

  private async openChannel(listener: EOTCChannelListener) {
    // const tempUnsubscribe = this.unsubscribeFromWebsocket;

    this.unsubscribeFromWebsocket = await EOTCWebSocketManager.subscribe({
      onEvent: (e) => this.onMessage(e),
      onConnection: listener.onConnection,
    });
  }

  public sendRequest = async (data: EOTCRequest) => {
    await EOTCWebSocketManager.request({
      message: data,
      successMatcher: (m) => m.success === true,
      failureMatcher: (m) => m.success === false,
    });
  };

  public async closeChannel() {
    logger.warn('closing channel', this.channelName);

    this.unsubscribeFromWebsocket?.();
    this.unsubscribeFromWebsocket = undefined;

    EOTCWebSocketManager.disconnect('EOTC closeChannel');
  }

  public async authenticate(getAccessToken: EOTCChannelGetTokenListener) {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      throw new Error('no_access_token');
    }

    const authenticateMessage: AuthRequest = {
      type: EOTCRequestType.AUTHENTICATION,
      api_token: accessToken,
    };

    try {
      await EOTCWebSocketManager.request(
        {
          message: authenticateMessage,
          successMatcher: (m) => {
            if ('auth' in m) {
              return m.auth === 'successful' && m.success === true;
            }

            return false;
          },
          failureMatcher: (m) => m.success === false,
        },
        20_000,
      );
    } catch (e) {
      logger.warn(`${wssLogPrefix}Failed to authenticate:`, e);

      this.handleRequestFailure(e);
    }
  }

  private handleRequestFailure = async (e) => {
    logger.error(`${wssLogPrefix} ${this.channelName} request failure;`, e);
  };

  private onMessage(event: WSPacket) {
    const message = event;

    this.listeners.forEach((listener) => {
      listener.onMessage(message);
    });
  }
}
