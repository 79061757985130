export const WSEOTCChannelName = 'EOTC';

export const EOTCTradeSides = {
  BUY: 'BUY',
  SELL: 'SELL',
} as const;
export type EOTCTradeSide = (typeof EOTCTradeSides)[keyof typeof EOTCTradeSides];

export enum EOTCRequestType {
  AUTHENTICATION = 'AUTH',
  QUOTE_REQUEST = 'RFQ',
  EXECUTE_TRADE = 'EXECUTE',
}

export enum EOTCQuoteStatus {
  Open = 'Open',
  PendingFill = 'PendingFill',
}

export enum WSPacketType {
  ExecutionReport = 'ExecutionReport',
  NotAuthorized = 'NotAuthorized',
  QuoteBooked = 'QuoteBooked',
  QuoteUpdate = 'QuoteUpdate',
}

export interface QuoteUpdateData {
  RFQID: string;
  amtInOppositeBuying: string;
  amtInOppositeSelling: string;
  bestAsk: string;
  bestAskMarket: string;
  bestAskWithFees: string;
  bestBid: string;
  bestBidMarket: string;
  bestBidWithFees: string;
  currency: string;
  endTime: string;
  orderQty: string;
  quoteID: string;
  quoteReqID: string;
  quoteStatus: EOTCQuoteStatus;
  symbol: string;
}

export interface ExecutionReportData {
  avgPx: string;
  clOrdID: string;
  cumAmt: string;
  cumQty: string;
  currency: string;
  fee: string;
  side: EOTCTradeSide;
  submitTime: string;
  symbol: string;
}
export interface WSPacketQuoteUpdate {
  data: QuoteUpdateData;
  error?: string;
  success: boolean;
  type: WSPacketType.QuoteUpdate;
  symbol: string;
}

export interface WSPacketQuoteBooked {
  error?: string;
  success: boolean;
  type: WSPacketType.QuoteBooked;
}

export interface WSPacketExecutionReport {
  data: ExecutionReportData;
  error?: string;
  success: boolean;
  type: WSPacketType.ExecutionReport;
}

export interface WSPacketAuth {
  auth: 'successful';
  error?: string;
  success: boolean;
}

export interface WSPacketNotAuthorized {
  type: WSPacketType.NotAuthorized;
  success: boolean;
}

export interface WSPacketError {
  error: string;
  success: boolean;
}

export type WSPacket = WSPacketAuth | WSPacketError | WSPacketExecutionReport | WSPacketNotAuthorized | WSPacketQuoteBooked | WSPacketQuoteUpdate;

export interface QuoteRequest {
  currency: string;
  orderQty: string;
  sent: string;
  side: EOTCTradeSide;
  symbol: string;
  type: EOTCRequestType.QUOTE_REQUEST;
  uuid: string;
}

export interface ExecuteRequest {
  orderQty: string;
  sent: string;
  side: EOTCTradeSide;
  type: EOTCRequestType.EXECUTE_TRADE;
  uuid: string;
}

export interface AuthRequest {
  api_token: string;
  type: EOTCRequestType.AUTHENTICATION;
}

export type EOTCRequest = QuoteRequest | ExecuteRequest;
