<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import useMQBreakpoints from '@exchange/libs/composables/shared/src/lib/useMQBreakpoints';
import { type ExportItem, ExportState, ExportType } from '@exchange/libs/exports/service/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';

import CollapsedExportItemRecord from './CollapsedExportItemRecord.vue';
import ExportItemDownload from './ExportItemDownload.vue';

const props = defineProps({
  collapsed: { type: Boolean, required: true },
  downloadItem: { type: Function as PropType<(id: string, isTradeItem: boolean) => Promise<void>>, required: true },
  fetchItem: { type: Function as PropType<(id: string, isTradeItem: boolean) => Promise<void>>, required: true },
  getLocalTime: { type: Function as PropType<(utcDateString: string, formatString?: string) => string>, required: true },
  item: { type: Object as PropType<ExportItem>, required: true },
});

const { t } = useI18n({ useScope: 'global' });

const { isMD, isLG, isXL } = useMQBreakpoints();
const bigScreen = computed(() => isLG.value || isXL.value);

const formattedTime = (time: string) => props.getLocalTime(time, CONSTANTS.DATE_FORMAT);
const getItemsToShow = (arr: Array<string>, emptyIsAll = false) => {
  if (arr.length) {
    return [...arr]
      .sort((a, b) => a.localeCompare(b))
      .map((e) => e.replace(/_/g, '/'))
      .join(', ');
  }

  return t(emptyIsAll ? 'fundamentals.all' : 'fundamentals.emptyValues.default');
};

const created = computed(() => formattedTime(props.item.created));
const expiration = computed(() => {
  if (props.item.state === ExportState.inProgress) {
    return t('fundamentals.emptyValues.default');
  }

  return formattedTime(props.item.expiration);
});
const timeFrame = computed(() => {
  const { to, from } = props.item;
  const dateFormat = CONSTANTS.HISTORY_DROPDOWN_DISPLAYED_DATE_FORMAT_COMPACT;

  if (to && from) {
    const showTo = props.getLocalTime(to, dateFormat);
    const showFrom = props.getLocalTime(from, dateFormat);

    return `${showFrom} - ${showTo}`;
  }

  return t('modules.accountHistory.export.fullHistory');
});
const accounts = computed(() => getItemsToShow(props.item.accounts));
const markets = computed(() => getItemsToShow(props.item.markets, props.item.type === ExportType.trades));
const currencies = computed(() => getItemsToShow(props.item.currencies));
</script>

<template>
  <ot-grid-table-row
    class="export-table-row"
    :collapsed="collapsed"
  >
    <div class="requested-column">
      <span>{{ created }}</span>
    </div>
    <div class="type-column">
      <span>{{ $t(`modules.exports.type.${item.type}`) }}</span>
    </div>
    <div
      v-if="bigScreen"
      class="timeframe-column"
    >
      <span>{{ timeFrame }}</span>
    </div>
    <div
      v-if="isMD || bigScreen"
      class="expiry-column"
    >
      <span>{{ expiration }}</span>
    </div>
    <div class="action-column">
      <export-item-download
        class="export-table-row__download"
        :item="item"
        :fetch-item="fetchItem"
        :download-item="downloadItem"
      />
    </div>
    <template #collapsed>
      <div class="export-table-row__collapsed-container">
        <collapsed-export-item-record
          v-if="!bigScreen"
          :label="$t('modules.exports.table.headers.timeframe')"
          :value="timeFrame"
        />
        <collapsed-export-item-record
          :label="$t('modules.exports.table.headers.accounts')"
          :value="accounts"
        />
        <collapsed-export-item-record
          :label="$t('modules.exports.table.headers.markets')"
          :value="markets"
        />
        <collapsed-export-item-record
          :label="$t('modules.exports.table.headers.currencies')"
          :value="currencies"
        />
        <collapsed-export-item-record
          v-if="!(isMD || bigScreen)"
          :label="$t('modules.exports.table.headers.expiry')"
          :value="expiration"
        />
      </div>
    </template>
  </ot-grid-table-row>
</template>

<style lang="scss">
.export-table-row {
  .action-column {
    display: flex;
    justify-content: flex-end;
  }

  &__collapsed-container {
    display: grid;
    padding-bottom: 20px;
    color: rgb(var(--v-theme-text-secondary));
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}
</style>
